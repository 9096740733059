import { useEffect, useState } from 'react'
import plans from 'const/plans'
import {
  addWeeks,
  isAfter,
  isSameDay,
  startOfDay,
  startOfToday,
} from 'date-fns'
import useUserProfile from 'hooks/useUserProfile'

const useUserPlan = (uid) => {
  const { data: profile, uid: profileUid } = useUserProfile(uid)
  const [parentPlan, setParentPlan] = useState(null)
  const today = startOfToday()

  useEffect(() => {
    const checkParentPlan = async () => {
      if (!profile?.parentAccountUid || !profileUid) {
        return
      }

      try {
        const response = await fetch(
          `/api/team/check-parent-plan?uid=${profileUid}`,
        )
        const data = await response.json()
        if (response.ok) {
          setParentPlan(data.parentPlan)
        }
      } catch (error) {
        console.error('Error checking parent plan:', error)
      }
    }

    checkParentPlan()
  }, [profile?.parentAccountUid, profileUid])

  if (!profile || (profile?.parentAccountUid && !parentPlan)) {
    return null
  }

  // If user has parent account with active subscription, use parent's plan
  if (parentPlan) {
    const parentPlanId = parentPlan.plan?.id || 'free'
    return {
      hasActiveSubscription: true,
      plan: plans[parentPlanId],
      paidTill: parentPlan.paidTill,
      isOverdue: false,
      expireIn1Week: false,
      userLimit: parentPlan.plan?.userLimit || plans[parentPlanId]?.userLimit,
      accounts: 0,
      isChildAccount: true,
    }
  }

  const planId = profile?.plan?.id || 'free'

  const isOverdue =
    planId !== 'free' &&
    !profile?.plan?.hasActiveSubscription &&
    profile?.plan?.paidTill &&
    (isAfter(today, new Date(profile?.plan?.paidTill)) ||
      isSameDay(today, new Date(profile?.plan?.paidTill)))

  const expireIn1Week =
    planId !== 'free' &&
    profile?.plan?.paidTill &&
    !profile?.plan?.hasActiveSubscription &&
    !isOverdue &&
    isAfter(
      startOfDay(addWeeks(today, 1)),
      startOfDay(new Date(profile?.plan?.paidTill)),
    )

  const overduePlan = {
    ...plans.free,
    title: plans[planId].title,
    id: planId,
    price: plans[planId].price,
    subscriptionUrl: plans[planId].subscriptionUrl,
    subscriptionId: plans[planId].subscriptionId,
  }

  return {
    hasActiveSubscription: profile?.plan?.hasActiveSubscription,
    plan: isOverdue ? overduePlan : plans[planId],
    paidTill: profile?.plan?.paidTill,
    isOverdue,
    expireIn1Week,
    userLimit: profile?.plan?.userLimit || plans[planId]?.userLimit,
    accounts: profile?.plan?.accounts || plans[planId]?.accounts,
  }
}

export default useUserPlan
