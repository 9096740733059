import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { useTranslation } from 'next-i18next'
import { Fade, JackInTheBox } from 'react-awesome-reveal'
import { useFirestoreDocData } from 'reactfire'

import Button from 'components/Button'
import RevealPlaceholder from 'components/RevealPlaceholder'
import SlideContent from 'components/SlideContent'
import SlideLayout from 'components/SlideLayout'
import SlideMedia from 'components/SlideMedia'
import TextfitResponsive from 'components/TextfitResponsive'
import Timer from 'components/Timer'
import getCdnUrl from 'utils/getCdnUrl'

export default function Challenge({
  isChallenge,
  presentation,
  session,
  sessionSlide,
  slide,
  slideId,
  sessionRef,
  uid,
  theme,
}) {
  const [visibility, setVisibility] = useState({})
  const [answerTimestamp, setAnswerTimestamp] = useState(null)
  const [tempOptions, setTempOptions] = useState([])

  const { t } = useTranslation()
  const userSlideRef = sessionRef
    .collection('slides')
    .doc(slideId)
    .collection('users')
    .doc(uid)

  const { data: userSlide } = useFirestoreDocData(userSlideRef)

  const question = slide?.question || {}
  const options = question?.options || []
  const isTimerStarted = isChallenge
    ? userSlide?.isTimerStarted
    : sessionSlide?.isTimerStarted
  const isTimerComplete = isChallenge
    ? userSlide?.isTimerComplete
    : sessionSlide?.isTimerComplete
  const answerArr = slide?.question?.answerArr || []
  const userAnswerArr = userSlide?.answerArr || []
  const isAnswerShown = isChallenge
    ? userSlide?.isAnswerShown
    : sessionSlide?.isAnswerShown

  useEffect(() => {
    setAnswerTimestamp(Date.now())

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        textVisible: true,
      }))
    }, 0)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        imageVisible: true,
      }))
    }, 300)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        optionsVisible: true,
      }))
    }, 500)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        timerVisible: true,
      }))

      setAnswerTimestamp(Date.now())

      if (userSlideRef && session?.mode === 'challenge') {
        userSlideRef.set(
          {
            timerStartedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        )
      }
    }, 1200)
  }, [])

  const handleOptionClick = (optionId) => {
    if (
      isAnswerShown ||
      (userAnswerArr.length > 0 && userAnswerArr.length === answerArr.length) ||
      (tempOptions.length === (answerArr.length || 1) &&
        !tempOptions.includes(optionId))
    ) {
      return
    }

    const newUserAnswerArr = [...tempOptions]
    if (newUserAnswerArr.includes(optionId)) {
      newUserAnswerArr.splice(newUserAnswerArr.indexOf(optionId), 1)
    } else {
      newUserAnswerArr.push(optionId)
    }

    if (answerArr.length > 1) {
      setTempOptions([...newUserAnswerArr])
      return
    }

    userSlideRef.set(
      {
        answerArr: newUserAnswerArr,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        answerTime: Date.now() - answerTimestamp,
        isAnswerShown:
          answerArr.length > 0 && answerArr.length === newUserAnswerArr.length,
      },
      { merge: true },
    )
  }

  const onSubmit = async () => {
    userSlideRef.set({
      answerArr: tempOptions,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isAnswerShown: true,
      answerTime: Date.now() - answerTimestamp,
    })
  }

  const getButtonDisabled = (optionId) => {
    if (isAnswerShown) {
      return ![...userAnswerArr, ...answerArr].includes(optionId)
    }
    if (isTimerComplete) {
      return !userAnswerArr.includes(optionId)
    }
    if (
      userAnswerArr.length > 0 &&
      userAnswerArr.length === (answerArr.length || 1)
    ) {
      return !userAnswerArr.includes(optionId)
    }
    return false
  }

  const getButtonVariant = (optionId) => {
    if (isAnswerShown) {
      if (answerArr.includes(optionId)) {
        return 'success'
      }
      if (userAnswerArr.includes(optionId) && !answerArr.includes(optionId)) {
        return 'danger'
      }
    }

    if (
      (tempOptions.length > 0 ? tempOptions : userAnswerArr).includes(optionId)
    ) {
      return 'primary'
    }

    return 'default'
  }

  const getOptionClassName = (optionId) => {
    if (isAnswerShown) {
      if (userAnswerArr.includes(optionId) && answerArr.includes(optionId)) {
        return 'flex w-full h-full animate__animated animate__heartBeat'
      }

      if (userAnswerArr.includes(optionId) && !answerArr.includes(optionId)) {
        return 'flex w-full h-full animate__animated animate__shakeX'
      }
    }
    return 'flex w-full h-full'
  }

  const isActiveOption = (optionId) =>
    isAnswerShown && answerArr.includes(optionId)

  const timer = slide?.settings?.timer || 30
  const onTimerComplete = () => {
    setTimeout(() => {
      userSlideRef.update({
        isTimerComplete: true,
        isAnswerShown: true,
      })
    }, 1000)
  }

  const isTimerVisible = isChallenge
    ? session?.settings?.showTimer &&
      timer &&
      visibility.timerVisible &&
      !isAnswerShown
    : presentation?.settings?.showPlayerTimer &&
      isTimerStarted &&
      !isTimerComplete &&
      !userAnswerArr?.length

  return (
    <div className="flex h-full w-full">
      {isTimerVisible && (
        <div className="fixed left-1/2 right-auto top-2 -translate-x-1/2 transform">
          <JackInTheBox effect="bounce">
            <Timer
              duration={timer}
              onComplete={onTimerComplete}
              theme={theme}
            />
          </JackInTheBox>
        </div>
      )}
      <SlideLayout
        slide={slide}
        text={
          <RevealPlaceholder visible={visibility.textVisible}>
            <Fade className="h-full w-full" triggerOnce>
              <TextfitResponsive
                className="formatted-content text-main relative mx-auto flex h-full min-h-[60px] w-full flex-col justify-center text-gray-900"
                style={{ lineHeight: '1.125' }}
                mode="multi"
              >
                <SlideContent
                  content={slide.content || slide.title}
                  placeholder={t('slide.questionText')}
                />
              </TextfitResponsive>
            </Fade>
          </RevealPlaceholder>
        }
        media={
          <RevealPlaceholder visible={visibility.imageVisible}>
            <Fade
              className="relative mb-4 h-full min-h-[30vh] w-full flex-1"
              triggerOnce
            >
              <div className="h-full flex-1 items-center">
                <div className="h-full w-full">
                  <SlideMedia slide={slide} />
                </div>
              </div>
            </Fade>
          </RevealPlaceholder>
        }
        options={
          <div className="pb-4">
            <RevealPlaceholder visible={visibility.optionsVisible}>
              <Fade triggerOnce>
                <div className="grid-options">
                  {options.map((option, index) => (
                    <div key={option.id}>
                      <Fade
                        delay={index * 200}
                        className={
                          isActiveOption(option.id)
                            ? 'relative z-10 w-full'
                            : 'w-full'
                        }
                        triggerOnce
                      >
                        <div className={getOptionClassName(option.id)}>
                          <Button
                            className="h-full"
                            disabled={getButtonDisabled(option.id)}
                            variant={getButtonVariant(option.id)}
                            block
                            onClick={() => handleOptionClick(option.id)}
                            size="responsive"
                          >
                            {option.imageUrl ? (
                              <img
                                className="max-h-24"
                                src={getCdnUrl(option.imageUrl)}
                                alt=""
                              />
                            ) : (
                              option.value ||
                              t('slide.option', { option: index + 1 })
                            )}
                          </Button>
                        </div>
                      </Fade>
                    </div>
                  ))}
                </div>
              </Fade>
            </RevealPlaceholder>
            {answerArr.length > 1 && (
              <div className="mx-auto w-full max-w-xl">
                <div className="mt-1 text-center text-sm">
                  Выберите {answerArr.length} варианта ответов
                </div>
                {(!isChallenge ||
                  (isChallenge && !isAnswerShown) ||
                  userSlide?.correct === undefined) && (
                  <Button
                    className="mt-1"
                    disabled={isTimerComplete || userAnswerArr.length > 0}
                    onClick={onSubmit}
                    type="submit"
                    size="responsive"
                    variant="success"
                    block
                  >
                    {userAnswerArr.length > 0
                      ? t('user.answerForm.accepted')
                      : t('user.answerForm.answer')}
                  </Button>
                )}
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}
